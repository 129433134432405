import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'container']

  toggle(event) {
    const checkBox = event.detail.switchElement.switch.checkboxInputTarget
    const checked  = checkBox.checked
    this.containerTarget.classList.toggle('hide', !checked)

    this.#markForDestruction(!checked);
  }

  #markForDestruction(destroy) {
    this.containerTarget.querySelectorAll('input[name*="_destroy"]').forEach(input => {
      input.value = destroy ? 'true' : 'false';
    });
  }
}
